import { Component, Vue } from 'nuxt-property-decorator'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TranslateResult } from 'vue-i18n'
import { namespace } from 'vuex-class'
import { BindingHelpers } from '~/node_modules/vuex-class/lib/bindings'
import { Product } from '~/mixins/CurrentProduct'

const layout: BindingHelpers = namespace('layout')

@Component
export default class UtilsMixin extends Vue {
  @layout.Getter isGrossSelected: any

  get priceTypeLabel(): TranslateResult {
    return this.isGrossSelected ? this.$t('price.gross') as string : this.$t('price.net') as string
  }

  isTileCalculator(product: Product | null) {
    return product?.labels.includes('tile_calculator')
  }
}
