






















































































































































































































  import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { Products, FilteringAttributeType, Orderpath } from '@one/types'
  import { Wishlist } from '@one/types/dist/orderpath/app'
  import { cloneDeep, isObject, isEqual, isEmpty } from 'lodash-es'
  // eslint-disable-next-line import/no-extraneous-dependencies
  import { TranslateResult } from 'vue-i18n'
  import { BindingHelpers } from 'vuex-class/lib/bindings'
  import LazyHydrate from 'vue-lazy-hydration'
  import { converters } from '@one/core'
  import AddToCartMixin from '../../../mixins/AddToCartMixin'
  import { RoutePagination } from '~/plugins/route-utils/router'
  import { CombinedFilterChangeEvent, PricingFilterChangeEvent } from '~/utils/ProductsListing'
  import UtilsMixin from '~/mixins/UtilsMixin'
  import { ProductItem } from '~/plugins/gtm/gtm'
  import { WishlistInterface } from '@/components/organisms/product/OneProductTileRightActions.vue'

  const categories: BindingHelpers = namespace('categories')
  const products = namespace('products')
  const account = namespace('account')
  const cart = namespace('cart')
  const layout = namespace('layout')
  const wishlist = namespace('wishlist')
  const stocks = namespace('stocks')
  const cms = namespace('cms')

  const ALL_PRICE_SORT_OPTIONS: Array<string> = [
    'GROSS_PRICE_ASC',
    'NET_PRICE_ASC',
    'GROSS_PRICE_DESC',
    'NET_PRICE_DESC',
  ]

  interface LocalBasicWishlistOrCart {
    id: string
    name: string
  }

  interface AddToCartInterface extends Orderpath.App.Cart.Requests.AddProductToCart {
    id: string
  }

  interface AddToWishlistInterface extends Wishlist.Requests.AddNumberOfProductsInWishlistOption {
    wishlist: WishlistInterface
  }

  enum LayoutTypes {
    MINI_LIST = 'MINI_LIST',
    LIST = 'LIST',
    TILES = 'TILES',
  }

  @Component({
    components: {
      OneCategoryDescription: () =>
        import('~/components/molecules/boxes/OneCategoryDescription.vue'),
      OneSidebarHeader: () => import('~/components/molecules/sidebars/OneSidebarHeader.vue'),
      OneToolbarList: () => import('./OneToolbarList.vue'),
      OneListingBanner: () => import('~/components/molecules/banners/OneListingBanner.vue'),
      OneSelectedFilters: () => import('~/components/organisms/filter/OneSelectedFilters.vue'),
      OneSidebar: () => import('~/components/molecules/sidebars/OneSidebar.vue'),
      OneCategoryTree: () => import('~/components/organisms/categoryTree/OneCategoryTree.vue'),
      OneFiltersContainer: () => import('~/components/molecules/filters/OneFiltersContainer.vue'),
      OnePagination: () => import('~/components/organisms/pagination/OnePagination.vue'),
      OneMobileProductsList: () => import('~/components/molecules/lists/OneMobileProductsList.vue'),
      OneProductTechnicalData: () =>
        import('~/components/organisms/product/OneProductTechnicalData.vue'),
      OneProductTitleLabels: () =>
        import('~/components/molecules/images/OneProductTitleLabels.vue'),
      OnePriceFilter: () => import('~/components/molecules/filters/OnePriceFilter.vue'),
      LazyHydrate,
    },
  })
  export default class OneCatalogViewList extends mixins(AddToCartMixin, UtilsMixin) {
    @Prop({
      required: false,
      default: false,
      type: Boolean,
    })
    readonly isSearch!: boolean

    @Prop({
      required: false,
      default: '',
      type: String,
    })
    readonly listingTitle!: string

    @Prop({
      required: false,
      default: '',
      type: String,
    })
    readonly listingTitleLabel!: string

    rangeValue: Array<Number> = [0, 0]
    shouldShowCategoriesBanner: boolean = false
    productsLayoutName: LayoutTypes = LayoutTypes.LIST
    layoutTypesMapping: Record<LayoutTypes, Function> = {
      [LayoutTypes.MINI_LIST]: () => import('./OneProductMinilistView.vue'),
      [LayoutTypes.LIST]: () => import('./OneProductListView.vue'),
      [LayoutTypes.TILES]: () => import('../catalog/OneProductTileView.vue'),
    }

    mainComponentClass = 'one-catalog-view-list'
    selectedFiltersFromUrl = {}

    @categories.Getter getSelectedCategoryId: any
    @categories.Action selectCategory: any
    @categories.Action clearSelectedCategory: any
    @categories.Getter getSelectedCategory: any
    @categories.Getter getTraverseTreeForCategory: any
    @products.Mutation('SET_PRICE') setPrice: any
    @products.Mutation('SET_PRODUCTS_DISPLAY_MODE') setProductsDisplayMode: any
    @products.Getter getSortingOptions: any
    @products.Getter getCurrentPage: any
    @products.Getter getTotalProducts: any
    @products.Getter getCategoryCount: any
    @products.Getter getTotalPages: any
    @products.Getter getPerPage: any
    @products.Getter getSortCriteria: any
    @products.Getter getProductOrderUnit: any
    @products.Getter getProduct: any
    @products.Getter getSearchedText: any
    @products.Getter getAttributesValue: any
    @products.Getter getCurrentProducts: any
    @products.Getter getCategorySelected: any
    @products.Getter getProductPrice: any
    @products.Getter getPriceFilter: any
    @products.Getter getPrice: any
    @products.Getter getDisplayMode: any
    @products.Getter getProductImage: any
    @products.State(state => state.productsList.attributes.byId) filtersById: any
    @products.State(state => state.productsList.price) priceFilter: any
    @products.State(state => state.productsList.selectedFilters) selectedFilters: any
    @products.Mutation('CLEAR_SELECTED_FILTERS') clearSelectedFilters: any;
    @products.State(state => state.productsList.categoriesCount) categoriesCount: any
    @products.State(state => state.productsList.priceFilters) storedPriceFilter: any
    @products.Getter getDefaultSortForCatalogBrowse: any
    @account.Getter isCompanyCustomerType: any
    @account.State currentUser: any

    @wishlist.Getter getCurrentWishlistId: any
    @products.Action fetchPaginatedProducts: any
    @wishlist.Action addProductToWishlist: any
    @wishlist.Getter getBasicWishlists: any
    @wishlist.Getter getBasicWishlistById: any
    @wishlist.Getter getCurrentWishlist: any
    @wishlist.Getter getWishlistProduct: any
    @layout.Getter getCurrency: any
    @layout.State(state => state.priceGross) isPriceGross!: boolean
    @layout.Getter priceType: any
    @layout.State menuFiltersShow: any
    @layout.Mutation('TOGGLE_FILTERS_MENU') toggleMenuFilters: any
    @cart.Getter getBasicCarts: any
    @cart.Getter getBasicCartById: any
    @cart.Getter getCurrentCart: any
    @stocks.Getter hasWarehouses!: boolean
    @cms.State(state => state.configuration.registrationEnabled) isRegistrationEnabled: any

    destroyed() {
      this.$plugins.onUnmounted(this.mainComponentClass)
    }

    @Watch('isPriceGross')
    changeSortOption(val: boolean, oldVal: boolean) {
      this.updateRoute({
        page: 1,
        sortCriteria: this.getSortCriteria?.replace(oldVal ? 'GROSS' : 'NET', val ? 'GROSS' : 'NET'),
      })
    }

    @Watch('rangeSliderValue')
    onPriceFilterChanged() {
      this.changeStateSlider()
    }

    @Watch('getCategorySelected', { immediate: true })
    changeRoute(isSearchedInCategory: boolean) {
      if (this.isSearch && process.client) {
        if (!isSearchedInCategory) {
          this.$routes.removeCategoryFromURL()
        }
      }
    }

    @Watch('totalItems', { immediate: true })
    toggleCategoriesBanner(items: number = 0) {
      if (this.isSearch && process.client) {
        this.shouldShowCategoriesBanner = !!(!items && (!isEmpty(this.selectedFilters) || this.getCategorySelected))
      } else {
        this.shouldShowCategoriesBanner = false
      }
    }

    @Watch('selectedFilters', { immediate: true })
    switchToParentCategoryIfCategoryNotAvailable() {
      const categoriesList = Object.keys(this.categoriesCount)
      const categoriesListIsAvailable = !!categoriesList.length
      if (
        this.getSelectedCategory?.parent &&
        categoriesListIsAvailable &&
        !categoriesList.includes(this.getSelectedCategoryId)
      ) {
        this.onCategoryClick(this.getSelectedCategory.parent)
      }
    }

    @Watch('$route.query.filtersQuery', { immediate: true })
    getSelectedFiltersFromUrl(newVal: any, oldVal: any) {
      if (
        isObject(newVal) && isObject(oldVal) &&
        (isEqual(newVal, oldVal))
      ) {
        return
      }
      const filtersQuery: string | Array<(string | null)> = this.$route.query.filtersQuery
      this.selectedFiltersFromUrl = {}
      let splitFilters: Array<string> | undefined = []

      if (typeof filtersQuery === 'string') {
        splitFilters = filtersQuery.split(':')
        this.selectedFiltersFromUrl[splitFilters[0]] = [splitFilters[1]]
      } else if (typeof filtersQuery === 'object') {
        Object.values(filtersQuery)?.forEach((item: string | null) => {
          splitFilters = item?.split(':')
          if (!splitFilters) {
            return
          }
          if (!this.selectedFiltersFromUrl[splitFilters[0]]) {
            this.selectedFiltersFromUrl[splitFilters[0]] = [splitFilters[1]]
          } else {
            this.selectedFiltersFromUrl[splitFilters[0]] = [...this.selectedFiltersFromUrl[splitFilters[0]], splitFilters[1]]
          }
        })
      } else {
        return
      }

      Object.keys(this.selectedFiltersFromUrl).every(async (el) => {
        if (!Object.keys(this.filtersById).includes(el)) {
          await this.$store.dispatch('products/fetchFilters', { filtersQuery: this.$route.query?.filtersQuery })
          return false
        }
        return true
      })
    }

    get selectedFiltersList() {
      return this.selectedFilters && Object.keys(this.selectedFilters).length ? this.selectedFilters : this.selectedFiltersFromUrl
    }

    get isBatchPricesLoading() {
      return (
        this.$wait.is('batch-prices-loading') &&
        this.getCurrentProducts &&
        this.getCurrentProducts.length > 0
      )
    }

    @Watch('isBatchPricesLoading', { immediate: true })
    onPriceLoaded(isLoading: boolean) {
      if (
        !isLoading &&
        this.getCurrentProducts &&
        this.getCurrentProducts.length > 0 &&
        process.client
      ) {
        const mappedProducts = this.getCurrentProducts
          .map((id: string) => {
            return this.getProduct(id)
          })
          .map((p: Products.Product, idx: number) => {
            const price = this.getProductPrice(p.id)
            return {
              item_name: p.name,
              item_id: p.id,
              item_list: this.listingName,
              item_brand: (p.brand && p.brand.name) || p.manufacturer.name,
              category: this.$routes.getCategoryPath(p.canonicalCategory) || '',
              price: price ? price.price : 0,
              discount: price ? price.catalogPrice - price.price : 0,
              index: idx,
            }
          })
        this.$gtm?.productImpression(mappedProducts)
      }
    }

    onProductClick(product) {
      const price = this.getProductPrice(product.id)
      this.$gtm.selectItem({
        item_id: product.id,
        item_name: product.name,
        item_brand: (product.brand && product.brand.name),
        category: this.$routes.getCategoryPath(product.canonicalCategory) || '',
        price: price?.price || 0,
        discount: price?.catalogPrice - price?.price,
      } as ProductItem, this.listingName)
    }

    @Watch('getCurrentProducts', { immediate: true })
    onCurrentProductsChanged(newProducts: Array<string>) {
      if (newProducts && newProducts.length && process.client) {
        this.preloadMainProductImages(newProducts)
        if (this.getCurrentPage === 1) {
          this.getSearchResults(newProducts, this.totalItems, this.getSearchedText)
        }
      }
    }

    getSearchResults(searchResults, totalItems, getSearchedText) {
      this.$gtm.productSearchResults({
        query: getSearchedText,
        skuProducts: searchResults,
        searchIndexes: totalItems,
      })
    }

    attributesValue(id: string): Array<Products.Attribute> {
      const attributesValue = this.getAttributesValue(id)
      return attributesValue.length ? attributesValue : null
    }

    onToggleFilters() {
      this.toggleMenuFilters(true)
    }

    async searchWithoutFilters() {
      await this.clearSelectedFilters()
      await this.clearSelectedCategory()
      this.$routes.search(this.getSearchedText, '', undefined, this.toggleCategoriesBanner)
    }

    updateRoute(pagination?: RoutePagination, cb?: Function) {
      const setFiltersQuery = () => {
        if (pagination && pagination.filtersQuery) {
          if (pagination.filtersQuery.length) {
            return pagination.filtersQuery
          }
        } else {
          return cloneDeep(this.$route.query.filtersQuery)
        }
        return undefined
      }
      const params: any = {
        filtersQuery: setFiltersQuery(),
        page: (pagination && pagination.page) || this.getCurrentPage,
        rows: (pagination && pagination.rows) || this.getPerPage,
        sortCriteria: (pagination && pagination.sortCriteria) || this.getSortCriteria,
      }
      if (pagination && pagination.price) {
        params.price = pagination && pagination.price
      } else {
        this.setPrice(null)
      }
      if (this.isSearch) {
        if (this.getSelectedCategoryId) {
          params.category = this.getSelectedCategoryId
        }
        if (this.getSearchedText) {
          params.q = this.getSearchedText
        }
      }
      const simplifyUrl = () => {
        const defaultRows = 15
        const defaultSortCriteria = this.getDefaultSortForCatalogBrowse
        const { filtersQuery, price, page, rows, sortCriteria } = params
        return (
          !filtersQuery &&
          !price &&
          rows === defaultRows &&
          sortCriteria === defaultSortCriteria &&
          page === 1
        )
      }
      this.$router.push(
        {
          path: this.isSearch
            ? this.$route.path
            : this.$routes.getCategoryPath() || this.$route.path,
          query: this.isSearch ? params : simplifyUrl() ? null : params,
        },
        cb ? cb() : () => ({}),
      )
    }

    preloadMainProductImages(products: Array<string>): void {
      products.map(id => this.$utils.preloadImage(this.getProductImage(id, 'SMALL_200', 0)))
    }

    get priceFilterFromBackend() {
      return this.storedPriceFilter
        ? {
          type: FilteringAttributeType.PRICE,
          NET: this.storedPriceFilter.NET,
          GROSS: this.storedPriceFilter.GROSS,
        }
        : null
    }

    get seoH1BySelectedCategory(): string | null {
      return this.getSelectedCategory?.seo?.h1
    }

    get totalItems(): Number {
      return this.isSearch
        ? this.getTotalProducts
        : this.getCategoryCount(this.getSelectedCategoryId)
    }

    get products(): Array<string> {
      if (this.isSearch) {
        return this.getCurrentProducts || []
      }
      return (this.getCategorySelected && this.getCurrentProducts) || []
    }

    get isPriceFilterVisible(): boolean {
      const shouldHidePriceFilter: boolean =
        this.$utils.isAnonymousAndPurchaseDisabled || this.$utils.isB2B
      return !shouldHidePriceFilter && !!this.rangeSliderValue
    }

    get getSortingOptionsFilterByPriceType() {
      const shouldHidePriceSorting: boolean =
        this.$utils.isAnonymousAndPurchaseDisabled || this.$utils.isB2B
      const netOrGross = !this.isPriceGross ? 'GROSS' : 'NET'
      const removePriceSortByTypePrice = [`${netOrGross}_PRICE_ASC`, `${netOrGross}_PRICE_DESC`]
      const removeOptions = !shouldHidePriceSorting
        ? removePriceSortByTypePrice
        : ALL_PRICE_SORT_OPTIONS
      return this.getSortingOptions.filter((el: any) => !removeOptions.includes(el.code))
    }

    get rangeSliderValue() {
      return this.getPriceFilter(this.priceType)
    }

    get rangeSliderMin() {
      return this.rangeSliderValue && this.rangeSliderValue.min
    }

    get rangeSliderMax() {
      return this.rangeSliderValue && this.rangeSliderValue.max
    }

    get loginToBuyLabel(): string | TranslateResult {
      return this.isRegistrationEnabled
        ? (this.$t('product_page.login_or_register_to_buy') as string)
        : (this.$t('product_page.login_to_buy') as string)
    }

    get listingName(): string {
      return this.isSearch ? 'search' : 'catalog'
    }

    get carts(): Array<Orderpath.App.Cart.Responses.BasicCartInfo | LocalBasicWishlistOrCart> {
      if (this.$auth.isAuthenticated) {
        return Object.keys(this.getBasicCarts).map((id: string) => this.getBasicCartById(id))
      }
      return (
        (this.getCurrentCart && [
          {
            id: this.getCurrentCartId,
            name: this.getCurrentCart.name,
          },
        ]) ||
        []
      )
    }

    get wishlists(): Array<Wishlist.Responses.BasicWishlist | LocalBasicWishlistOrCart> {
      if (this.$auth.isAuthenticated) {
        return Object.keys(this.getBasicWishlists).map((id: string) =>
          this.getBasicWishlistById(id),
        )
      }
      return (
        (this.getCurrentWishlist && [
          {
            id: this.getCurrentWishlistId,
            name: this.getCurrentWishlist.name,
          },
        ]) ||
        []
      )
    }

    get menuAccount() {
      return {
        booleanValue: this.menuFiltersShow,
        uid: 'menu-filters',
      }
    }

    getProductUnit(productId: string) {
      const product = this.getProduct(productId)

      return this.isTileCalculator(product)
        ? this.getProduct(productId).measurementUnits.contentUnitDescription
        : this.getProductOrderUnit(productId)
    }

    computedProductPrice(productId: string, isGross: boolean) {
      const product = this.getProduct(productId)

      if (this.isTileCalculator(product)) {
        const product = this.getProduct(productId)
        const productPrice = this.getProductPrice(productId, isGross)

        if (!productPrice) {
          return null
        }

        if (productPrice?.price) {
          const convertedPrice = converters.toPackagingQuantityPrice(
            productPrice?.price,
            product.measurementUnits.packingQuantity,
          )
          productPrice.price = convertedPrice ? parseFloat(convertedPrice) : convertedPrice
        }

        if (productPrice?.catalogPrice) {
          const convertedUnit = converters.toPackagingQuantityPrice(
            productPrice?.catalogPrice,
            product.measurementUnits.packingQuantity,
          )
          productPrice.contentUnit = convertedUnit ? parseFloat(convertedUnit) : convertedUnit
        }

        return productPrice
      } else {
        return this.getProductPrice(productId, isGross)
      }
    }

    onCategoryClick(categoryId: string) {
      this.selectCategory(categoryId)
      this.updateRoute({
        page: 1,
        price: this.currentPriceParam,
      })
    }

    getPathForProduct(product: any) {
      return this.$routes.getProductPathById(product && product.id)
    }

    showProduct(e: Products.Product) {
      this.$router.push(this.$routes.getProductPathById(e.id) as string)
    }

    onAddToCart({ id, productId, amountOfProducts }: AddToCartInterface) {
      this.$wait.start(`add-to-cart-${productId}`)
      this.addToCart(productId, amountOfProducts, this.listingName, id).finally(() =>
        this.$wait.end(`add-to-cart-${productId}`),
      )
    }

    onLoginClick() {
      this.$router.push('/login')
    }

    onAddToWishlist({
      wishlist,
      productId,
      amountOfProducts,
    }: AddToWishlistInterface) {
      if (!this.hasWarehouses) {
        this.$notify({
          group: 'alert',
          type: 'error',
          text: this.$t('errors.no_warehouses_cart') as string,
        })
      } else {
        this.$wait.start(`add-to-wishlist-${productId}`)
        this.addProductToWishlist({
          wishlistId: wishlist?.id ?? this.getCurrentWishlistId,
          newProduct: {
            productId,
            amountOfProducts,
          },
        })
          .then(() => {
            this.$notify({
              group: 'alert',
              type: 'success',
              text: this.$t('product_page.alert_added_to_wishlist') as string,
            })
            const addedProduct = this.getWishlistProduct(wishlist?.id ?? this.getCurrentWishlistId)(productId)
            this.$gtm.addToWishlist(
              {
                item_id: addedProduct.id,
                item_name: addedProduct.name,
                item_brand: addedProduct.brand?.name || '',
                item_list_name: wishlist.name,
                category: this.$routes.getCategoryPath(addedProduct.canonicalCategory) || '',
                price: addedProduct.prices?.unitNet || 0,
                discount: addedProduct.prices?.catalogPriceNet - addedProduct.prices?.unitNet,
                quantity: amountOfProducts || 0,
              },
              addedProduct.prices.unitNet * (amountOfProducts || 0),
            )
          })
          .finally(() => this.$wait.end(`add-to-wishlist-${productId}`))
      }
    }

    canGetPage(page: number) {
      return page <= this.getTotalPages && page > 0
    }

    get currentPriceParam(): string | undefined {
      return this.getPrice
        ? `${this.priceType}:${this.rangeValue[0]}-${this.rangeValue[1]}`
        : undefined
    }

    async onPrevPage() {
      const prevPage: number = this.getCurrentPage - 1
      if (!this.canGetPage(prevPage)) {
        return
      }
      await this.scrollTop(250)
      this.updateRoute({
        page: prevPage,
        price: this.currentPriceParam,
      })
    }

    async onNextPage() {
      const nextPage: number = this.getCurrentPage + 1
      if (!this.canGetPage(nextPage)) {
        return
      }
      await this.scrollTop(250)
      this.updateRoute({
        page: nextPage,
        price: this.currentPriceParam,
      })
    }

    async onPageChanged(val: number) {
      if (this.canGetPage(val)) {
        await this.scrollTop(250)
        this.$utils.hideKeyboard()
        this.updateRoute({
          page: val,
          price: this.currentPriceParam,
        })
      }
    }

    async onFiltersChange(event: CombinedFilterChangeEvent) {
      /* TODO: Sprawdzić, czy mamy inny typ filtrów */
      // @ts-ignore
      const newFilter: string = `${event.nameCode}:${event.filterValue}`
      let filters: Array<string | null> | undefined = []
      const currentFilters: string | Array<string | null> = cloneDeep(
        this.$route.query.filtersQuery,
      )
      if (currentFilters) {
        if (Array.isArray(currentFilters)) {
          const indexOfChangedFilter: number = currentFilters.indexOf(newFilter)
          if (indexOfChangedFilter > -1) {
            currentFilters.splice(indexOfChangedFilter, 1)
          } else {
            currentFilters.push(newFilter)
          }
          filters = currentFilters.map(x => x)
          if (!filters.length) {
            filters = []
          }
        } else if (currentFilters === newFilter) {
          filters = []
        } else {
          filters = [currentFilters, newFilter]
        }
      } else {
        filters = [newFilter]
      }
      await this.scrollTop(250)
      this.updateRoute({
        filtersQuery: filters,
        page: 1,
        price: this.currentPriceParam,
      })
    }

    onFilterPriceClear() {
      this.setPrice(null)
      this.updateRoute({
        page: 1,
      })
    }

    async onRangeChange(data: PricingFilterChangeEvent) {
      this.rangeValue = data.value
      await this.scrollTop(250)
      this.updateRoute({
        page: 1,
        price: `${this.priceType}:${this.rangeValue[0]}-${this.rangeValue[1]}`,
      })
    }

    onFiltersClear() {
      this.updateRoute({
        filtersQuery: [],
        page: 1,
      })
    }

    async onRowsChanged(val: number) {
      await this.scrollTop(250)
      this.updateRoute({
        rows: val,
        page: 1,
        price: this.currentPriceParam,
      })
    }

    async onSortChanged(val: string) {
      await this.scrollTop(250)
      this.updateRoute({
        sortCriteria: val,
        price: this.currentPriceParam,
      })
    }

    changeStateSlider() {
      if (this.getPrice) {
        const parsedPrice: [number, number] | null = this.$routes.parsePriceString(this.getPrice)
        if (parsedPrice && this.isPriceInRange(parsedPrice[0], parsedPrice[1])) {
          this.rangeValue = parsedPrice
          return
        }
      }
      this.rangeValue = [this.rangeSliderMin, this.rangeSliderMax]
    }

    isPriceInRange(min: number, max: number): boolean {
      return this.rangeSliderValue.min <= min && max <= this.rangeSliderValue.max
    }

    onLayoutChange(layoutType: LayoutTypes) {
      this.$cookies.set('productsLayout', layoutType)
      this.productsLayoutName = layoutType
      this.setProductsDisplayMode(layoutType)
    }

    setProductsLayout(): void {
      const storedLayoutName = this.$cookies.get('productsLayout')
      if (!storedLayoutName) {
        this.productsLayoutName = this.getDisplayMode || 'LIST'
      } else if (Object.values(LayoutTypes).includes(storedLayoutName)) {
        this.productsLayoutName = storedLayoutName
        this.setProductsDisplayMode(storedLayoutName)
      } else {
        // Backward compatibility
        const oldMapping: Record<string, LayoutTypes> = {
          'one-product-list-view': LayoutTypes.LIST,
          'one-product-minilist-view': LayoutTypes.MINI_LIST,
          'one-product-tile-view': LayoutTypes.TILES,
        }
        // @ts-ignore
        this.productsLayoutName = (oldMapping[storedLayoutName] as string) || LayoutTypes.LIST
      }
    }

    setDefaultProductsLayout(): void {
      this.productsLayoutName = LayoutTypes.LIST
      this.$cookies.set('productsLayout', this.productsLayoutName)
    }

    getFakeComponentName(name: LayoutTypes): string {
      const layoutMap: Record<LayoutTypes, string> = {
        [LayoutTypes.LIST]: 'one-product-list-view',
        [LayoutTypes.MINI_LIST]: 'one-product-minilist-view',
        [LayoutTypes.TILES]: 'one-product-tile-view',
      }

      return layoutMap[name]?.replace(/one-/g, 'one-fake-')
    }

    get showProductsDisplayModes(): string | undefined {
      return ['lg', 'xl'].find(x => x === this.$mq)
    }

    created() {
      this.setProductsLayout()
    }

    mounted() {
      this.changeStateSlider()
      this.addResizeListener()
      this.$plugins.onMounted(this.mainComponentClass, this)
    }

    beforeDestroy() {
      this.removeResizeListener()
    }

    addResizeListener(): void {
      if (process.client) {
        window.addEventListener('resize', this.onResize)
      }
    }

    removeResizeListener(): void {
      if (process.client) {
        window.removeEventListener('resize', this.onResize)
      }
    }

    onResize(): void {
      !this.showProductsDisplayModes && this.setDefaultProductsLayout()
    }
  }
